<template>
  <div class="bg">
    <a href="#" title="Go To Top"
      ><img src="@/assets/footer/Main-logo.png" alt="lanna logo" class="logo"
    /></a>
    <div class="line"></div>
    <div class="icons">
      <a href="https://www.facebook.com/lpc.lannaproducts/"
        ><img src="@/assets/footer/fb.png" alt="facebook" class="icon"
      /></a>
      <a href="http://m.me/lpc.lannaproducts"
        ><img src="@/assets/footer/msg.png" alt="messenger" class="icon"
      /></a>
      <a href="https://lin.ee/ZXACLmc"
        ><img src="@/assets/footer/line.png" alt="line" class="icon"
      /></a>
    </div>

    <br />
    <br />

    <b-container>
      <b-row>
        <b-col class="left">
          <!-- TODO: Change text to i18n -->
          <p>{{ $t("footer.address") }}: {{ $t("contact.card1.address") }}</p>
          <p>
            {{ $t("footer.tel") }}:
            <a :href="'tel:' + tel1">{{ tel1 }}</a>
            ,
            <a :href="'tel:' + tel2">{{ tel2 }}</a>
          </p>
          <p>
            {{ $t("footer.email") }}:
            <a :href="'mailto:' + mail">{{ mail }}</a>
          </p>
        </b-col>

        <b-col class="right">
          <p>
            <router-link :to="`/${$i18n.locale}`">{{
              $t("nav.home")
            }}</router-link>
          </p>
          <p>
            <router-link :to="`/${$i18n.locale}/all-products`">{{
              $t("nav.products.allproducts")
            }}</router-link>
          </p>
          <p>
            <router-link :to="`/${$i18n.locale}/contact`">{{
              $t("nav.contact")
            }}</router-link>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  computed: {
    tel1() {
      return this.$t("contact.tel1");
    },
    tel2() {
      return this.$t("contact.tel2");
    },
    mail() {
      return this.$t("contact.mail");
    },
  },
};
</script>

<style scoped>
.bg {
  background-color: #2a3e47;
  color: white;
  padding: 40px;
}
.left p {
  text-align: left;
  padding-left: 20%;
  font-family: Cloud;
}
.right p {
  text-align: left;
  padding-left: 47%;
  font-size: 18px;
}
.icon {
  height: 50px;
  margin-right: 10px;
}
.logo {
  height: 80px;
}

a {
  color: white;
}
@media screen and (min-width: 720px) {
  .icons {
    display: inline-block;
  }
  .line {
    width: 42%;
    border-bottom: white solid 3px;
    text-align: center;
    display: inline-block;
    margin: 0 20px 0 20px;
  }
}
@media screen and (max-width: 420px) {
  .right {
    display: none;
  }
  .left p {
    padding: 0;
  }
}
</style>
