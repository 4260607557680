import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../i18n";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },

      {
        path: "online-shopping",
        name: "Shopping",
        component: () => import("../views/Shopping.vue"),
      },
      {
        path: "best-sellers",
        name: "BestSellers",
        component: () => import("../views/BestSellers.vue"),
      },
      {
        path: "all-products",
        name: "AllProducts",
        component: () => import("../views/AllProducts.vue"),
      },
      {
        path: "OEM",
        name: "OEM",
        component: () => import("../views/OEM.vue"),
      },

      {
        path: "about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "factory",
        name: "Factory",
        component: () => import("../views/Factory.vue"),
      },

      {
        path: "fb-content",
        name: "FbContent",
        component: () => import("../views/FbContent.vue"),
      },

      {
        path: "contact",
        name: "Contact",
        component: () => import("../views/Contact.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = "en";
  }
  // set the current language for i18n
  i18n.locale = language;
  // go to top of the new page
  window.scrollTo(0, 0);
  next();
});

export default router;
