<template>
  <div class="bg">
    <div class="grid">
      <!--      <iframe-->
      <!--        src="https://www.youtube-nocookie.com/embed/8msmQiNs6KM"-->
      <!--        title="YouTube video player"-->
      <!--        frameborder="0"-->
      <!--        autoplay="1"-->
      <!--        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
      <!--        allowfullscreen-->
      <!--      ></iframe>-->
      <iframe
        src="https://www.youtube.com/embed/3w_sbRJG0gc"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <div class="text">
        <p v-html="$t('home.vid')"></p>
        <button @click="toShop()">{{ $t("home.order") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toShop() {
      this.$router.push(`/${this.$i18n.locale}/online-shopping`);
    },
  },
};
</script>

<style scoped>
.bg {
  margin-right: 0%;
  background-image: url("../../assets/BG.png");
  background-size: 100%;
  background-position: right;
  background-attachment: fixed;
  padding: 12% 10% 20% 10%;
  position: relative;
  text-align: left;
}

video {
  width: 100%;
}

button {
  border: none;
  background-color: #2a3e47;
  width: 90px;
  height: 30px;
  color: white;
  border-radius: 2px;
  margin-bottom: 10%;
}

h1 {
  bottom: 0px;
  position: absolute;
}

@media screen and (min-width: 720px) {
  .grid {
    display: grid;
    grid-template-columns: auto auto;
  }

  .text {
    padding-left: 10%;
  }

  button {
    margin-bottom: 0;
  }

  p {
    font-size: 24px;
  }

  button {
    width: 100px;
    height: 40px;
    font-size: 20px;
  }

  iframe {
    width: 350px;
    height: 197px;
  }
}
</style>
