<template>
  <div id="app">
    <Header></Header>
    <div class="space"></div>
    <router-view />
    <Footer></Footer>
    <!-- <go-top :size="60" bg-color="#6ea4db"></go-top> -->
  </div>
</template>

<script>
import Header from "./components/nav/Header.vue";
import Footer from "./components/nav/Footer.vue";
// import GoTop from "@inotom/vue-go-top";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    // GoTop,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,700;1,700&display=swap");

@font-face {
  font-family: Cloud Bold;
  font-weight: Bold;
  src: url("./assets/fonts/Cloud Soft-Bold.ttf") format("truetype");
}

@font-face {
  font-family: Cloud;
  src: url("./assets/fonts/Cloud Soft-Light.ttf") format("truetype");
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: Cloud Bold, Cloud, sans-serif;
}
.space {
  /*margin-top: 15vw;*/
  margin-top: 105px;
  /* margin-top: 20%; */
}
@media screen and (max-width: 780px) {
  .space {
    margin-top: 76px;
  }
}
</style>
