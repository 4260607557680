<template>
  <b-dropdown-item :to="link">
    <b-icon icon="circle-fill" scale="0.4"></b-icon>
    {{ text }}
  </b-dropdown-item>
</template>

<script>
export default {
  props: ["text", "link"],
};
</script>
