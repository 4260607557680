<template>
  <div>
    <a href="https://www.facebook.com/lpc.lannaproducts/"> facebook </a>
    <div class="wrap">
      <Carousel />
      <Video />
      <About />
      <WhyUs class="mt-5" />

      <div class="mb-5">
        <h2 data-aos="fade" data-aos-easing="ease-in" data-aos-once="true">
          Vision
        </h2>
        <h3 data-aos="fade" data-aos-once="true">{{ $t("home.vision") }}</h3>
        <div
          class="vl"
          data-aos="grow"
          data-aos-duration="1000"
          data-aos-once="true"
          data-aos-delay="200"
        ></div>
        <h2 data-aos="fade" data-aos-easing="ease-in" data-aos-once="true">
          Mission
        </h2>
        <h3 data-aos="fade" data-aos-once="true">{{ $t("home.mission") }}</h3>
        <div
          class="vl"
          data-aos="grow"
          data-aos-duration="1000"
          data-aos-delay="200"
          data-aos-once="true"
        ></div>
        <h2
          data-aos="fade"
          data-aos-easing="ease-in"
          data-aos-once="true"
          id="fb"
        >
          Goal
        </h2>
        <h3 data-aos="fade" data-aos-once="true">{{ $t("home.goal") }}</h3>
      </div>
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Flpc.lannaproducts%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
        width="340"
        height="500"
        style="border: none; overflow: hidden"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import Video from "@/components/home/Video.vue";
import About from "@/components/home/About.vue";
import WhyUs from "@/components/home/WhyUs.vue";

import AOS from "aos";
import "aos/dist/aos.css";

export default {
  mounted() {
    AOS.init();
  },
  components: { Carousel, Video, About, WhyUs },
};
</script>

<style scoped>
/*  Animation starts here */
[data-aos="grow"] {
  /* transform: scaleY(0); */
  transform-origin: top;
  transform: scaleY(0);
  transition-property: transform;
}

/*  Animation ends here */
[data-aos="grow"].aos-animate {
  transform: scaleY(1);
  /* transform: scaleY(1) translateY(0); */
}

@keyframes line {
  0% {
    height: 0;
  }
  100% {
    height: 75vw;
  }
}

.vl {
  height: 300px;
  border-left: #2c3e50 solid 5px;
  position: relative;
  left: 50%;
  width: 0;
  margin: 2% 0 2% 0;
}

h2 {
  font-family: Prompt;
  font-size: 50px;
  padding: 5px;
}

a {
  position: sticky;
  top: 25%;
  float: right;
  z-index: 2;
  background-color: dodgerblue;
  color: white;
  padding: 8px;
  writing-mode: vertical-rl;
  font-size: 25px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  text-decoration: none;
}

.wrap {
  position: relative;
  top: -120px;
}

@media screen and (max-width: 780px) {
  a {
    font-size: 15px;
    padding: 5px;
  }

  .wrap {
    top: -80px;
  }
}
</style>
