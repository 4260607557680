<template>
  <div class="about" title="Click Me" @click="toAbout">
    <div class="text">
      <h1 class="dark">{{ $t("home.about") }}</h1>
      <h1 class="light">{{ $t("home.us") }}</h1>
      <div class="vl"></div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toAbout() {
      this.$router.push({ name: "About" });
    },
  },
};
</script>

<style scoped>
.about {
  /* background-image: url("../../assets/home/about.png"); */
  background-image: url("https://firebasestorage.googleapis.com/v0/b/lanna-web-9e989.appspot.com/o/home%2Fabout.png?alt=media&token=c6433fdf-a0e4-4a9f-90e7-2e5576a95124");
  background-size: contain;
  text-align: left;
  position: relative;
  height: 0;
  padding-top: 82%;
  cursor: pointer;
}
.about:active {
  /* background-image: url("../../assets/home/about_blur.png"); */
  background-image: url("https://firebasestorage.googleapis.com/v0/b/lanna-web-9e989.appspot.com/o/home%2Fabout_blur.png?alt=media&token=857d9f26-c707-43a6-bf33-e07851525ba0");
}
.text {
  background-color: transparent;
  position: absolute;
  font-family: Prompt;
  font-style: italic;
  left: 6%;
  top: -100px;
}
.text h1 {
  font-size: 80px;
}
.dark {
  color: #2a3e47;
  margin-bottom: 0%;
}
.light {
  color: white;
}
.vl {
  border-left: 10px solid white;
  height: 30vw;
  position: relative;
  left: 8%;
}
@media screen and (max-width: 600px) {
  .text h1 {
    font-size: 60px;
  }
  .text {
    top: -60px;
  }
  .vl {
    border-left: 7px solid white;
  }
}
</style>
