<template>
  <div>
    <h1 v-html="$t('home.why.title')" class="title"></h1>

    <div class="grid">
      <div>
        <img :src="images.gear" alt="gear" />
        <h2 v-html="$t('home.why.standard')"></h2>
      </div>
      <div>
        <img :src="images.hand" alt="hand" />
        <h2 v-html="$t('home.why.care')"></h2>
      </div>
      <div>
        <img :src="images.lab" alt="lab" />
        <h2 v-html="$t('home.why.innovation')"></h2>
      </div>
    </div>

    <b-container>
      <b-row class="image">
        <b-col><img :src="images.grid1" /></b-col>
        <b-col><img :src="images.grid2" /> </b-col>
        <b-col><img :src="images.grid3" /></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../firebase.config";
export default {
  data() {
    return {
      images: {
        gear: "",
        hand: "",
        lab: "",
        grid1: "",
        grid2: "",
        grid3: "",
      },
    };
  },
  mounted() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `home/${key}.png`)).then((url) => {
        this.images[key] = url;
      })
    );
  },
};
</script>

<style scoped>
@font-face {
  font-family: SOV_Thanamas;
  src: url("../../assets/fonts/SOV_Thanamas.ttf") format("truetype");
}
div {
  padding: 3%;
}
.title {
  margin-bottom: 3%;
  margin-top: 2%;
  padding: 0;
}
::v-deep p {
  font-family: Cloud;
  font-size: 22px;
}
::v-deep em {
  font-family: SOV_Thanamas;
  font-size: 90px;
}
.col {
  padding: 0px;
}
.grid img {
  height: 100px;
}
.image img {
  width: 85%;
}
.grid {
  display: grid;
  grid-template-columns: auto auto auto;
}
h2 {
  margin-top: 30px;
}
@media screen and (max-width: 780px) {
  h1 {
    padding: 40px;
  }
  .grid {
    display: block;
  }
}
</style>
