import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueFacebookPage from "vue-facebook-page";

Vue.config.productionTip = false;

Vue.use(VueFacebookPage);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
