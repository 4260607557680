<template>
  <div>
    <b-carousel :interval="4000" fade indicators controls>
      <b-carousel-slide :img-src="images.head01"></b-carousel-slide>
      <b-carousel-slide :img-src="images.head02"></b-carousel-slide>
      <b-carousel-slide :img-src="images.head03"></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { storage } from "../../../firebase.config";
import { getDownloadURL, ref } from "firebase/storage";
export default {
  data() {
    return {
      images: {
        head01: "",
        head02: "",
        head03: "",
      },
    };
  },
  async created() {
    Object.keys(this.images).forEach((key) =>
      getDownloadURL(ref(storage, `home/${key}.png`)).then((url) => {
        this.images[key] = url;
      })
    );
  },
};
</script>
