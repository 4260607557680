<template>
  <div>
    <!-- <div class="hl"></div> -->
    <b-navbar toggleable="lg" class="fixed-top">
      <b-navbar-brand :to="`/${$i18n.locale}`"
        ><b-img src="@/assets/logo.png" width="85px" class="logo"></b-img
      ></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown :text="$t('nav.products.name')">
            <dropdown-item
              :text="$t('nav.products.online')"
              :link="`/${$i18n.locale}/online-shopping`"
            />
            <dropdown-item
              :text="$t('nav.products.bestsellers')"
              :link="`/${$i18n.locale}/best-sellers`"
            />
            <dropdown-item
              :text="$t('nav.products.allproducts')"
              :link="`/${$i18n.locale}/all-products`"
            />
            <!-- <dropdown-item
              :text="$t('nav.products.oem')"
              :link="`/${$i18n.locale}/OEM`"
            /> -->
          </b-nav-item-dropdown>

          <b-nav-item-dropdown :text="$t('nav.articles.name')">
            <dropdown-item
              :text="$t('nav.articles.about')"
              :link="`/${$i18n.locale}/about`"
            />
            <dropdown-item
              :text="$t('nav.articles.factory')"
              :link="`/${$i18n.locale}/factory`"
            />
          </b-nav-item-dropdown>

          <!--          <b-nav-item-dropdown :text="$t('nav.news.name')">-->
          <!--            <dropdown-item-->
          <!--              :text="$t('nav.news.facebook')"-->
          <!--              :link="`/${$i18n.locale}/fb-content`"-->
          <!--            />-->
          <!--          </b-nav-item-dropdown>-->

          <b-nav-item :to="`/${$i18n.locale}/contact`">{{
            $t("nav.contact")
          }}</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item @click.prevent="switchLang('en')" class="lang">
            <img src="@/assets/header/EN.png" alt="eng" height="25px" />
            EN</b-nav-item
          >
          <div class="vl d-none d-lg-block"></div>
          <b-nav-item @click.prevent="switchLang('th')" class="lang"
            ><img src="@/assets/header/TH.png" alt="th" height="25px" />
            TH</b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import DropdownItem from "./DropdownItem.vue";
export default {
  components: { DropdownItem },
  methods: {
    switchLang(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$router.push({
          params: { lang: locale },
        });
      }
    },
  },
};
</script>

<style scoped>
.hl {
  height: 22px;
  background-color: #2a3e47;
}
.vl {
  margin-top: 13px;
  border-left: 2px solid #2a3e47;
  height: 35px;
}
.navbar {
  /* margin-top: 22px; */
  /* background-color: white; */
  background: linear-gradient(to bottom, #2a3e47 10px, white 0%);
  padding-top: 1.2%;
}
::v-deep .navbar-brand {
  padding: 5px 20px 5px 20px;
}
::v-deep .nav-link {
  font-size: 18px;
  padding: 20px 40px 5px 40px !important;
  color: #2a3e47 !important;
}
::v-deep .dropdown-menu {
  background-color: #2a3e47;
}
::v-deep .dropdown-item {
  color: white;
}
::v-deep .dropdown-item:hover {
  color: black;
}
::v-deep .dropdown-item:active {
  background-color: whitesmoke;
}
.lang ::v-deep .nav-link {
  font-family: Cloud;
  padding-right: 20px !important;
  padding-left: 20px !important;
}
@media screen and (max-width: 780px) {
  .logo {
    width: 60px;
  }
  .navbar {
    background: none;
    background-color: white;
  }
}
</style>
